<template>
  <div class="c-app">
    <TheSidebar />
    <div class="c-wrapper">
      <TheHeader />
      <div class="c-body">
        <main class="c-main">
          <CContainer fluid v-if="standardPlan === false">
            <transition name="fade">
              <router-view></router-view>
            </transition>
          </CContainer>
          <CContainer fluid v-if="standardPlan === true">
            <transition name="fade">
              <router-view v-if="isLogout"></router-view>
              <standard-plan v-else></standard-plan>
            </transition>
          </CContainer>
        </main>
      </div>
      <TheFooter />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
import StandardPlan from './StandardPlan'
import util from '@/util/standard'

export default {
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    StandardPlan,
  },
  data() {
    return {
      standardPlan: '',
    }
  },
  computed: {
    ...mapGetters(['users']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    isLogout() {
      return this.$route.name === 'Logout'
    },
  },
  methods: {
    planChecker() {
      let plan = this.users.currentPlan

      if (plan === 'standard' || plan === 'starter') {
        let standardChecker = util.standardChecker(this.uid)
        if (standardChecker === true) {
          //true = สมาชิกที่สามารถใช้งานต่อได้
          this.standardPlan = false
        } else if (standardChecker === false) {
          //false = สมาชิกที่ไม่ให้ใช้งาน
          this.standardPlan = true
        }
      } else {
        this.standardPlan = false
      }
    },
  },
  watch: {
    users() {
      this.planChecker()
    },
  },
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
