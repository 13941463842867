<template>
  <CSidebar fixed :minimize="minimize" :show.sync="show" v-if="checkCashier === false">
    <CSidebarBrand style="padding: 12px">
      <div class="row text-black c-sidebar-brand-full">
        <div class="col-4">
          <img :src="shopLogo" class="c-avatar-img" width="100%" alt=""
            onerror="this.onerror=null;this.src='https://cdn-icons-png.flaticon.com/512/4634/4634791.png';" />
        </div>
        <div class="col-8">
          <strong> {{ shopName }} </strong> <br />
          <small>
            {{ $t('currentPlan') }} : <b>{{ currentPlan }}</b>
          </small>
        </div>
      </div>
      <div class="c-sidebar-brand-minimized">
        <img :src="shopLogo" width="40" alt="" />
      </div>
    </CSidebarBrand>
    <CSidebarNav>
      <CSidebarNavTitle>{{ $t('QRCode') }}</CSidebarNavTitle>

      <CSidebarNavItem :name="$t('GenerateQRCode')" to="/" icon="cil-qr-code">
        <!-- <CSidebarNavItem
          name="รอรับที่ร้าน"
          to="/qrcode/pickup"
          :icon="{
            name: 'cil-circle',
            customClasses: 'c-sidebar-nav-icon c-icon iconsize',
          }"
        >
        </CSidebarNavItem> -->
        <!-- <CSidebarNavItem
          name="สั่งอาหารที่โต๊ะ"
          to="/qrcode/dine-in"
          :icon="{
            name: 'cil-circle',
            customClasses: 'c-sidebar-nav-icon c-icon iconsize',
          }"
        >
        </CSidebarNavItem> -->
      </CSidebarNavItem>
      <CSidebarNavTitle>{{ $t('management.txtManagement') }}</CSidebarNavTitle>
      <CSidebarNavItem :name="$t('management.shopBranch')" to="/shop" icon="cil-basket">
      </CSidebarNavItem>
      <CSidebarNavItem :name="$t('currentUser')" to="/user" icon="cil-user">
      </CSidebarNavItem>
      <br />
      <br />
      <CSidebarNavItem :name="$t('signOut')" to="/user/logout" icon="cil-account-logout">
      </CSidebarNavItem>
    </CSidebarNav>
    <CSidebarMinimizer class="d-md-down-none" @click.native="minimize = !minimize" />
  </CSidebar>

  <CSidebar fixed :minimize="minimize" :show.sync="show" v-else-if="checkCashier === true">
    <CSidebarBrand style="padding: 12px">
      <div class="row text-black c-sidebar-brand-full">
        <div class="col-4">
          <img :src="shopLogo" class="c-avatar-img" width="100%" alt=""
            onerror="this.onerror=null;this.src='https://cdn-icons-png.flaticon.com/512/4634/4634791.png';" />
        </div>
        <div class="col-8">
          <strong> {{ shopName }} </strong> <br />
          <small>
            {{ $t('employees') }}: <b>{{ cashierName }}</b>
          </small>
        </div>
      </div>
      <div class="c-sidebar-brand-minimized">
        <img :src="shopLogo" width="40" alt="" />
      </div>
    </CSidebarBrand>
    <CSidebarNav>
      <CSidebarNavTitle>{{ $t('QRCode') }}</CSidebarNavTitle>

      <CSidebarNavItem :name="$t('GenerateQRCode')" to="/" icon="cil-qr-code">
      </CSidebarNavItem>

      <br />
      <br />
      <CSidebarNavItem :name="$t('signOut')" to="/user/logout" icon="cil-account-logout">
      </CSidebarNavItem>
    </CSidebarNav>
    <CSidebarMinimizer class="d-md-down-none" @click.native="minimize = !minimize" />
  </CSidebar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      minimize: false,
      show: 'responsive',
      favIcon: '/silom-pos.png',
      showRegister: '',
      cashierName: '',
    }
  },
  computed: {
    ...mapGetters(['users', 'shops']),
    checkCashier() {
      let check = localStorage.getItem('x-auth')

      if (check !== null) {
        this.cashierName = check
        return true
      } else {
        return false
      }
    },
    loginwith() {
      return localStorage.getItem('loginwith')
    },
    shopLogo() {
      if (this.shops.length > 0) {
        if (this.shops[0].remoteImagePath === undefined) {
          return 'https://cdn-icons-png.flaticon.com/512/4634/4634791.png'
        } else {
          return this.shops[0].remoteImagePath
        }
      } else {
        return 'https://cdn-icons-png.flaticon.com/512/4634/4634791.png'
      }
    },
    shopName() {
      if (this.shops.length > 0) {
        return this.shops[0].shopName
      }
    },
    currentPlan() {
      if (this.shops.length > 0) {
        return this.shops[0].currentPlan
      }
    },
  },
  mounted() {
    this.$store.dispatch('getUser')
    this.$store.dispatch('getShop')
    this.$root.$on('toggle-sidebar', () => {
      const sidebarOpened = this.show === true || this.show === 'responsive'
      this.show = sidebarOpened ? false : 'responsive'
    })
    this.$root.$on('toggle-sidebar-mobile', () => {
      const sidebarClosed = this.show === 'responsive' || this.show === false
      this.show = sidebarClosed ? true : 'responsive'
    })
  },
}
</script>

<style>
html:not([dir='rtl']) .c-sidebar-nav-dropdown-items .c-sidebar-nav-icon,
html:not([dir='rtl']) .c-sidebar-nav-dropdown-items .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
  margin-left: -30px;
}

.iconsize {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 70px;
  flex: 0 0 70px;
  height: 0.55rem;
  font-size: 0.09375rem;
  text-align: center;
  -webkit-transition: 0.25s;
  transition: 0.25s;
  fill: currentColor;
}
</style>
