<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <p class="font-weight-bold">{{ shopName }}</p>
        <div class="c-avatar">
          <div class="circle" v-if="!logo">
            <h3>{{ nameIcon }}</h3>
          </div>
          <img alt="" :src="logo" class="c-avatar-img" v-else />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownItem to="/shop">
      <span><CIcon name="cil-basket" /> ร้านค้าและสาขา</span>
    </CDropdownItem>
    <CDropdownItem to="/user">
      <span><CIcon name="cil-user" /> บัญชีผู้ใช้</span>
    </CDropdownItem>
    <CDropdownItem to="/user/logout">
      <span><CIcon name="cil-account-logout" /> ออกจากระบบ </span>
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      nameIcon: '',
    }
  },
  computed: {
    ...mapGetters(['shops']),
    logo() {
      if (this.shops.length !== 0) {
        return this.shops[0].remoteImagePath
      }
    },
    shopName() {
      if (this.shops.length !== 0) {
        this.nameIcon = this.shops[0].shopName.charAt(0).toUpperCase()
        return this.shops[0].shopName
      }
    },
  },
}
</script>

<style scoped>
.circle {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  background-color: #acd7c6;
  border-radius: 50%;
}
.circle h3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  color: #2f9576;
  font-size: 15px;
}
</style>
