export default {
  standardChecker(uid) {
    let uids = [
      'X7iyGASaWgMPilJGsxiOcxgr10w2',
      'wXiGf52liSSEqwRPf7iYwanO7dR2',
      'UTt4N8kJEIUjPWVHae745ISX9ws2',
      'jJ6QUgW3KFNefRmD8CNVjXo9pG03',
      'mqtFn3Nv5qMb4qWqIyfUhHQl5c02',
      'umfCY4fbyugEFSb1E8UzCxQJ6WB2',
      'qF7stzGCwIM3JHenIH6hgP7E7G83',
      'StuDJR7P0AXX576Arynjx9VvZEb2',
      'GszejKUfsfcii53YFXXmMCT72KH3',
      'LAA532XRyHWKFZOadMB9FRZ88tr1',
      'LbOH0zvgCTgVSYPEtpivKf5uHZh2',
      'ZzYwN2cvObbCbzQ15SaQu8ST73m2',
      'Uty7Y5N8DtQt7XdNt1OYQb4bv1I2',
      'bQmYRkoS4GdfPgppxSzDIVbH7g83',
      'RwCVeEYLeBP6X3EWsdje5YQp3uu1',
      '3wEEvu8JX0eig3mizoQkry1yHRz1',
    ]

    const result = uids.find((u) => u === uid)

    if (result) {
      return true
    } else {
      return false
    }
  },
}
